import React, { useState, useEffect, useRef } from 'react';

const pubPath = process.env.PUBLIC_URL;
function Error404() {
  useEffect(() => {

    // 컴포넌트가 언마운트될 때 클릭 이벤트 리스너를 제거한다.
   
    const footerElement = document.getElementById('footer');
    const headerElement = document.getElementById('header');
      footerElement.remove();
      headerElement.remove();
  }, []);

  return (
      <div className='error404'>
        <div>
          <h1>404</h1>
          <p>원하시는 페이지를 찾을 수 없습니다.</p>
          <p>
            찾으려는 페이지의 주소가 잘못 입력되었거나,<br />
            주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다.<br />
            입력하신 페이지의 주소가 정확한지 다시 한번 확인해 주세요.
          </p>
          <a href="/">빌딩온 홈페이지로 돌아가기 <img src={pubPath + "/images/btn/longArrow.png"} alt="빌딩온 홈페이지로 돌아가기" /></a>
        </div>
      </div>
  );
}

export default Error404;


			