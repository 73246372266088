import React, { useState, useEffect, Suspense } from 'react';
import { Route, Routes, useLocation  } from 'react-router-dom';
import ChannelService from "utils/ChannelService";
import Common from 'components/common';
import Html_head from 'components/html_head';

import Header from 'components/header';
import Footer from 'components/footer';
import * as layout from 'utils/layout';
import Error404 from 'pages/errorPage/404';
import { naverLogData } from 'utils/site_func';


//React.lazy 필요시 동적로드
// import Main from 'pages/main';
const Main = React.lazy(() => import('pages/main'));
const Sub = React.lazy(() => import('pages/sub'));


// js
export default function MyApp() {
  ChannelService.loadScript(); // 채널 톡

  ChannelService.boot({
    pluginKey: "618da5de-77c4-4e33-8bf1-54d39541da7d",
  });

  //오픈시 전송
  ChannelService.onShowMessenger(() => {naverLogData(4,'0') });//'채널톡'
  
  const location = useLocation();
  const isApplication = location.pathname.includes('application');

  const includePaths = ['/', '/trade/realtrade', '/company/*', '/invest/*', '/consultation/*', '/recruit/*', '/application/*', '/private', '/agreement'];

  // Get the current path 
  const currentPath = window.location.pathname;

  // Check if the current path is in the array of paths where Header and Footer should be included
  const shouldRender  = includePaths.some(path => currentPath.startsWith(path));


  return (
    <>
      {/* top */}
      <Header />
      <Routes>
        <Route path='/' element={<Main />}></Route>
        {/* <Route path="/" component={Main} /> */}

        
        <Route path='/trade/*' element={<Suspense fallback={<div>Loading...</div>}><Sub /></Suspense>}></Route>
    
        <Route path='/company/*' element={<Suspense fallback={<div>Loading...</div>}><Sub /></Suspense>}></Route>

        <Route path='/invest/*' element={<Suspense fallback={<div>Loading...</div>}><Sub /></Suspense>}></Route>

        <Route path='/consultation/*' element={<Suspense fallback={<div>Loading...</div>}><Sub /></Suspense>}></Route>

        <Route path='/recruit/*' element={<Suspense fallback={<div>Loading...</div>}><Sub /></Suspense>}></Route>

        <Route path='/application/*' element={<Suspense fallback={<div>Loading...</div>}><Sub /></Suspense>}></Route>

        <Route path='/private' element={<Suspense fallback={<div>Loading...</div>}><Sub /></Suspense>}></Route>
        <Route path='/agreement' element={<Suspense fallback={<div>Loading...</div>}><Sub /></Suspense>}></Route>
        
        {/* <Route path='/*' element={<Suspense fallback={<div>Loading...</div>}><Sub /></Suspense>}></Route> */}
        <Route path='*' element={<Error404/>}></Route>
     
      </Routes>
      <Footer />
    </>
  );
}
