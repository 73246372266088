import React from 'react';
import { Link } from 'react-router-dom';

import * as layout from 'utils/layout';
const pubPath = process.env.PUBLIC_URL;

// HeaderTop, All 값 넣기
function MenuTop() {
    const lis = layout.topTxt.map((topT, index) => ( // topTxt를 반복해서 담아줌
        <li key={index}>
            <Link to={topT.menu_url}>{topT.menu_nm}</Link>
        </li>
    ));

    return <ul className="sitemapTitle">{lis}</ul>;
}

function MenuAll() {// allTxt배열을 변수로 담아 그안에 배열을 리턴해줌
    const lis = layout.allTxt.map((allT, index) => {
        const a_lis = allT.map((aT, idx) => (
            <li key={idx}>
                <Link to={aT.menu_url}>{aT.menu_nm}</Link>
            </li>
        ));

        return (
            <li key={index}>
                <ul>{a_lis}</ul>
            </li>
        );
    });

    return <ul className="sitemapSub">{lis}</ul>;
}

function Footer() {
    return (
        <footer id="footer">
            <div className="ftTop">
                <h3><img src={pubPath + '/images/index/logo_w.png'} alt="빌딩온 부동산중개(주) buildingon" /></h3>
            </div>
            <div className="ftInfo">
                <ul>
                    <li><p>빌딩온부동산중개㈜</p></li>
                    <li><Link to="/private">개인정보보호정책</Link></li>
                    <li><Link to="/agreement">서비스이용약관</Link></li>
                </ul>
                <p>
                    대표이사 : 김주환
                    <span className="empty"></span>
                    주소 : 서울특별시 강남구 논현로 615
                    <span className="empty"></span>
                    TEL : 02-2088-5477
                    <span className="empty"></span>
                    사업자등록번호 : 656-86-03629
                    <span className="empty"></span>
                    중개등록번호 : 11680-2023-00376
                </p>
                <address>COPYRIGHT 2022 BUILDINGON .ALL RIGHTS RESERVED.</address>
            </div>
        </footer>
    );
}
export default Footer;