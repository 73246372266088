import React from 'react';

import 'assets/css/function.css';
import 'assets/css/layout.css';
import 'assets/css/animate.min.css';
import 'assets/css/pagination.css';


function Html_head() {
    return (
        {/* 컴포넌트의 내용 */}
    );
  }
  
  export default Html_head;