
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop(props) {
    const { pathname } = useLocation();

    // 페이지 이동시 상단으로 이동
    // 0.1 초의 간격을 둔이유는 브라우저는 뒤로가기시 페이지 유지하려는 것때문에
    useEffect(() => { 
        const timeoutId = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 50);
    }, [pathname]);

    return <>{props.children}</>;
}