export function animateItems() {
  let item = document.querySelectorAll('.ibcon');
  item = item.length > 0 ? item : document.querySelectorAll('.conItems')
  
    let cnt = 0;
    const addActive = setInterval(() => {
      if (cnt < item.length) {
        item[cnt].classList.add('ani');
        cnt++;
      } else {
        clearInterval(addActive);
      }
    }, 130);
  }

  /**
   * 네이버 로그 분석 :
   * dataTp : 타입
   * dataVal 값
   */
export const naverLogData = (dataTp, dataVal) => {
  setTimeout(function () {

    const existingScript = document.querySelector('body script');
    if (existingScript) {
      existingScript.remove();
    }
    const nasaScript = document.createElement('script');
    nasaScript.type = 'text/javascript';
    nasaScript.innerHTML = `
                        if (!wcs_add) var wcs_add = {};
                        wcs_add["wa"] = "s_1e2b3af9817a";
                        var _nasa={};
                        if(window.wcs) _nasa["cnv"] = wcs.cnv("${dataTp}","${dataVal}"); 
                        wcs_do(_nasa);
                        console.log('_nasa:', _nasa); 
                        // console.log('wcs_add:', wcs_add); 
                            `;
    document.querySelector('footer').appendChild(nasaScript);
  }, 500);
}