
// export function handleScroll(event) {
// 	let layoutScrollPosition = 0;
// 	let layoutTicking = false;
// 	layoutScrollPosition = window.scrollY;

// 	if (!layoutTicking) {
// 		window.requestAnimationFrame(() => {
// 			fnTopBtnShow(layoutScrollPosition);

// 			layoutTicking = false;
// 		});

// 		layoutTicking = true;
// 	}
// }
// export function fnTopBtnShow(scrollPos) {
// 	const btnPageTop = document.getElementById('btnPageTop');
// 	if (scrollPos > 300) {
// 		btnPageTop.style.display = 'flex';
// 	} else {
// 		btnPageTop.style.display = 'none';
// 	}
// 	return false;
// }

// 이부분 변경시 subTitle.jsx num, 수정해야함
// layout.js #header ul.topMenu
// 각페이지 snBox

export const topTxt = [
	{ menu_url: '/trade/realtrade', menu_nm: '매물 및 실거래정보' },
	{ menu_url: '/company/greeting', menu_nm: '회사소개' },
	{ menu_url: '/invest/columns', menu_nm: '투자정보' },
	{ menu_url: '/consultation/buy', menu_nm: '상담신청' },
	{ menu_url: '/recruit/intro', menu_nm: '채용안내' },
	{ menu_url: '/application/youtubeList', menu_nm: '컨텐츠' }
]

export const allTxt = [
	[
		{ menu_url: '/trade/realtrade', menu_nm: '매물 정보' }	,
		{ menu_url: '/trade/transaction', menu_nm: '실거래 정보' }	,
	],
	[
		{ menu_url: '/company/greeting', menu_nm: 'CEO 인사말' },
		// { menu_url: '/company/history', menu_nm: '기업연혁' },
		{ menu_url: '/company/bld', menu_nm: '컨설턴트소개' },
		{ menu_url: '/company/business', menu_nm: '사업분야' },
		// { menu_url: '/company/pr', menu_nm: '홍보자료' },
		{ menu_url: '/company/map', menu_nm: '오시는길' },
		{ menu_url: '/company/transition', menu_nm: '회사전경' },
		// { menu_url: '/company/meeting', menu_nm: '회의실 안내' }
	],
	[
		{ menu_url: '/invest/columns', menu_nm: '부동산칼럼' },
		// { menu_url: '/invest/magazine', menu_nm: '매거진' },
		// { menu_url: '/invest/book', menu_nm: '자산관리메뉴얼' },
		// { menu_url: '/invest/guide', menu_nm: '투자가이드' },
		{ menu_url: '/invest/case', menu_nm: '투자사례' }
	],
	[
		{ menu_url: '/consultation/buy', menu_nm: '매수의뢰' },
		{ menu_url: '/consultation/sellRequest', menu_nm: '매도의뢰' },
		{ menu_url: '/consultation/consulting', menu_nm: '컨설팅의뢰' }
	],
	[
		// { menu_url: '/recruit/intro', menu_nm: '직무소개' },
		// { menu_url: '/recruit/vision', menu_nm: '비전' },
		// { menu_url: '/recruit/education', menu_nm: '교육 및 육성' },
		{ menu_url: '/recruit/jobapply', menu_nm: '인재 채용' },
		{ menu_url: '/recruit/apply', menu_nm: '입사 지원' }
	],
	[
		{ menu_url: '/application/youtubeList', menu_nm: '유튜브 채널' },
		{ menu_url: '/application/lecture', menu_nm: '강의 소개' }
	]
]