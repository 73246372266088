import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import * as layout from 'utils/layout';
const pubPath = process.env.PUBLIC_URL;

// 개발서버에서는 자동 public환경변수 적용됨 그외 src={pubPath + '/images/index/logo_w.png'}


function Header() {
  const [imgCls, setImgCls] = useState('');
  const [isDivVisible, setDivVisible] = useState(false);

  const handleDocumentClick = (e) => { // 웹페이지 클릭시 메뉴창 닫힘
    if(document.body.offsetWidth <= 800){
      return;
    }
    e.stopPropagation();
    const elmtId = document.getElementById('header');
    const elmtCls = document.getElementsByClassName('allMenuView')[0];
    let elmtClsDisplay = elmtCls.style;
    if(elmtClsDisplay.display === 'block'){
      if (window.scrollY < 900) {
        elmtId.className = '';
      }
      elmtClsDisplay.display = 'none';
      setImgCls('');
    }
  };
  
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  function fnTopMenuView(pType) {
    // console.log(window.scrollY);
    const elmtId = document.getElementById('header');
    const elmtCls = document.getElementsByClassName('allMenuView')[0];
    let elmtClsDisplay = elmtCls.style;

    
    if (pType === 1 && (elmtClsDisplay.display === 'none' || elmtClsDisplay.display === '')) {
      elmtId.className = 'topFix';
      elmtClsDisplay.display = 'block';
      setImgCls('imgClick');
    } else if (pType === 1 || pType === 2) {
      if (window.scrollY < 900) {
        elmtId.className = '';
      }
      elmtClsDisplay.display = 'none';
      setImgCls('');
    }
  }

  // HeaderTop, All 값 넣기
  function MenuTop() {
    const lis = layout.topTxt.map((topT, index) => ( // topTxt를 반복해서 담아줌
      <li key={index}>
        <a href='' onClick={(e) => {
          fnTopMenuView(1);
          e.preventDefault();
          e.stopPropagation();
        }}>
          {topT.menu_nm}
        </a>
      </li>
    ));
  
    return <ul className="topMenu">{lis}</ul>;
  }
  
  function MenuAll() { // allTxt를 먼저 담아준 뒤 topTxt를 반복해서 담아줌
    const lis = layout.topTxt.map((topT, index) => {
      const allT = layout.allTxt[index];
  
      const a_lis = allT.map((aT, idx) => (
        <li key={idx} className={hiddenMenus.includes(index) ? 'hidden' : ''}>
          {aT.menu_nm === '오시는길' ? (
            <a href='/company/map'>{aT.menu_nm}</a>
          ) : (
            <Link to={aT.menu_url}>{aT.menu_nm}</Link>
          )}
          {/* <Link to={aT.menu_url} onClick={(e) => {
          // fnTopMenuView(1);
        }}>
            {aT.menu_nm}
          </Link> */}
        </li>
      ));

      return (
        <li key={index} className={hiddenMenus.includes(index) ? 'openBtn' : 'closeBtn'} onClick={(e) => { handleClick(e, index); }}>
          <Link to={topT.menu_url}>{topT.menu_nm}</Link>
          <ul>{a_lis}</ul>
        </li>
      );
    });
  
    return <ul className="allMenu">{lis}</ul>;
  }
  
  const [hiddenMenus, setHiddenMenus] = useState([]);
  const handleClick = (event, index) => { // 하위메뉴클릭시 닫기
    event.stopPropagation();
    const tagName = event.target.tagName; // 태그
    const width = window.innerWidth;
    // console.log(tagName);
    if (width > 0 ) {
    //if (width > 0 && width < 800) {
      if (tagName != 'A') {
        if (hiddenMenus.includes(index)) {
          setHiddenMenus(hiddenMenus.filter((item) => item !== index));
        } else {
          setHiddenMenus([...hiddenMenus, index]);
        }
      }else{
        fnTopMenuView(1);
      }
    }
  };

  return (
    <section id="header">{/* className="topFix" */}
      {/* 요청 : header:hover시 h1속 로고이미지 common/logo.png로 변경 */}
      <h1><a href="/">
        <img src={pubPath + '/images/index/logo_w.png'} alt="buildingon" />
      </a></h1>
      <div id="topMenuView">
        <MenuTop />
        {/* 요청 : topMenu li:hover시 allMenu display:block로 변경 위에서 아래로 펼쳐짐 */}
        <div className="allMenuView">
          <MenuAll />
        </div>
      </div>
      <a href='#' className="topBtnmenu" onClick={(event) => {
        fnTopMenuView(1);
        event.preventDefault();
        event.stopPropagation();
      }}>
        <img className={imgCls} src='/images/common/menu_w.png' alt="전체메뉴" />
      </a>
    </section>
  );
}

export default Header;